import PropTypes from 'prop-types';
import styles from './ChangePassword.module.scss';
import { useSimpleTexts } from 'components/sanaText';
import { SanaForm, FormGroup, NewPasswordFormGroup, PasswordField, useFormLockerOnSubmit } from 'components/objects/forms';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'behavior/pages/changePassword';
import { FormName } from 'behavior/pages';
import { formatText } from 'utils/formatting';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const ChangePasswordFormBlock = ({ id, isDesignerMode }) => {
  const [
    oldPasswordTitle,
    newPasswordTitle,
    repeatPasswordTitle,
    minPasswordPlaceholderTemplate,
    samePasswordError,
    comparisonErrorMessage,
  ] = useSimpleTexts([
    'ChangePassword_OldPassword',
    'ChangePassword_NewPassword',
    'ChangePassword_RetypePassword',
    'CreateAccount_MinPasswordLength',
    'Validation_SamePassword',
    'ChangePassword_CompareFailed',
  ]).texts;

  const minLength = useSelector(({ settings: { profile } }) => profile?.minPasswordLength);
  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minLength]);

  const dispatch = useDispatch();

  const { name, email } = useSelector(state => state.user);

  const onSubmit = useFormLockerOnSubmit(data => {
    dispatch(changePassword(data.old, data.new));
  }, FormName.ChangePassword, []);

  return (
    <SanaForm
      className={styles.form}
      name={`${FormName.ChangePassword}_${id}`}
      onSubmit={useHandlerLockerInPreview(onSubmit)}
    >
      <FormGroup
        fieldName="old"
        fieldTitle={oldPasswordTitle}
        fieldComponent={PasswordField}
        autoComplete="off"
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
        }}
        disabled={isDesignerMode}
      />
      <NewPasswordFormGroup
        fieldName="new"
        fieldTitle={newPasswordTitle}
        placeholder={newPasswordPlaceholder}
        aria-describedby="pswd"
        additionalValidation={{
          notEqualsTo: { to: 'old', message: samePasswordError },
        }}
        userData={[name, email]}
        disabled={isDesignerMode}
      />
      <FormGroup
        fieldName="repeat"
        fieldTitle={repeatPasswordTitle}
        fieldComponent={PasswordField}
        autoComplete="off"
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
          equalsTo: { to: 'new', message: comparisonErrorMessage },
        }}
        disabled={isDesignerMode}
      />
      <SanaForm.SubmitRow
        textKey="Save"
        className={styles.noBottomMargin}
        onClick={isDesignerMode ? e => e.preventDefault() : undefined}
      />
    </SanaForm>
  );
};

ChangePasswordFormBlock.propTypes = {
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default ChangePasswordFormBlock;
